import React from 'react';
import { graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx';

import useSiteMetadata from '@hooks/useSiteMetadata';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import Image from '@commons/image/Image';
import { Header } from '@commons/header/Header';
import { StandardSection } from '@commons/standard-section/StandardSection';

import businessImg from '@images/case-studies/business benefits image.svg';
import technicalImg from '@images/case-studies/technical highlights image.svg';
import approachImg from '@images/case-studies/our approach image.svg';
import clientsIcon from '@images/case-studies/group.svg';
import goalIcon from '@images/case-studies/goal.svg';

import * as styles from './case-study.module.scss';

const businessBenefitsImg = (
  <div className={styles.imageContainer}>
    <img loading="lazy" src={businessImg} alt="Requirements" />
  </div>
);

const ourApproachImg = (
  <div className={styles.imageContainer}>
    <img loading="lazy" src={approachImg} alt="Requirements" />
  </div>
);

const technicalInsightImg = (
  <div className={styles.imageContainer}>
    <img loading="lazy" src={technicalImg} alt="Requirements" />
  </div>
);

const clientsImg = (
  <div className={styles.iconWrapper}>
    <img loading="lazy" src={clientsIcon} alt="Clients icon" />
  </div>
);

const goalImg = (
  <div className={styles.iconWrapper}>
    <img loading="lazy" src={goalIcon} alt="Goals Icon" />
  </div>
);

export default function CaseStudyTemplate(props) {
  const {
    markdownRemark: {
      frontmatter: {
        seoTitle,
        category,
        description,
        serviceType,
        ourApproach,
        businessGoal,
        seoDescription,
        clientTypeName,
        technologyStack,
        businessBenefits,
        technicalInsights,
        featuredImage,
      },
    },
  } = props.data;

  const { siteURL } = useSiteMetadata();

  return (
    <Layout {...props} oldPage>
      <SEO seoDescription={seoDescription} seoTitle={seoTitle} featuredImage={siteURL + featuredImage.publicURL} />
      <div className={styles.caseStudyTemplateContainer}>
        <Header
          title="Case study"
          descriptionTitle={description}
          src={'BG_case_study_1440.jpg'}
          headerTitleH1={false}
        />

        <StandardSection>
          <div>
            <div className={styles.servicesContainer}>
              {[...category, ...serviceType].map((service, index) => (
                <div className={styles.servicesButton} key={`${service}-${index}`}>
                  {service}
                </div>
              ))}
            </div>
            <div className={styles.bussinesBackground}>
              <h2 className={styles.mainTitle}>Business background</h2>
              <div>
                {clientTypeName && (
                  <div className={styles.clientType}>
                    <div className={styles.titleWrapper}>
                      {clientsImg}
                      <h3>Client type</h3>
                    </div>
                    <span>{clientTypeName} </span>
                  </div>
                )}
                {businessGoal && (
                  <div className={styles.businessGoal}>
                    <div className={styles.titleWrapper}>
                      {goalImg}
                      <h3>Business goal</h3>
                    </div>
                    <Markdown>{businessGoal}</Markdown>
                  </div>
                )}
              </div>
            </div>
          </div>
        </StandardSection>

        <StandardSection title="Our approach" className={styles.grayBg}>
          {ourApproach && (
            <div className={styles.flexedSection}>
              <div> {<Markdown>{ourApproach}</Markdown>}</div>
              <div> {ourApproachImg}</div>
            </div>
          )}
        </StandardSection>
        <StandardSection title="Business benefits">
          {businessBenefits && (
            <div className={styles.flexedSection}>
              {businessBenefitsImg}
              {<Markdown>{businessBenefits}</Markdown>}
            </div>
          )}
        </StandardSection>
        <StandardSection title="Technical highlights" className={styles.grayBg}>
          {technicalInsights && (
            <div className={styles.flexedSection}>
              {<Markdown>{technicalInsights}</Markdown>}
              {technicalInsightImg}
            </div>
          )}
        </StandardSection>
        <StandardSection id="technology-stack-section" title="Technology stack">
          <div className={styles.technology}>
            {technologyStack.map((technology, index) => (
              <div className={styles.technologyItem} key={`${technology}-${index}`}>
                <Image alt={technology} image={technology} />
              </div>
            ))}
          </div>
        </StandardSection>
      </div>
    </Layout>
  );
}
export const caseStudyQuery = graphql`
  query CaseStudyByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        category
        seoTitle
        description
        ourApproach
        serviceType
        businessGoal
        caseStudyName
        seoDescription
        clientTypeName
        technologyStack {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        businessBenefits
        technicalInsights
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
      }
    }
  }
`;
