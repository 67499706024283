// extracted by mini-css-extract-plugin
export var businessGoal = "case-study-module--business-goal--2246d";
export var bussinesBackground = "case-study-module--bussines-background--f2c93";
export var caseStudyTemplateContainer = "case-study-module--case-study-template-container--59362";
export var clientType = "case-study-module--client-type--ad103";
export var flexedSection = "case-study-module--flexed-section--cb7c2";
export var grayBg = "case-study-module--gray-bg--8f399";
export var iconWrapper = "case-study-module--icon-wrapper--99859";
export var imageContainer = "case-study-module--image-container--c10d3";
export var mainTitle = "case-study-module--main-title--b7fa7";
export var servicesButton = "case-study-module--services-button--5d99e";
export var servicesContainer = "case-study-module--services-container--d20f7";
export var technology = "case-study-module--technology--f494f";
export var technologyItem = "case-study-module--technology-item--f595e";
export var titleWrapper = "case-study-module--title-wrapper--0ce52";